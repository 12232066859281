import React from "react";
import Footer from "src/components/Footer_en";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/en/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/en/our-products/" target="_self">
                      Our Products
                    </a>
                    <ul>
                      <li>
                        <a href="/en/our-products/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/en/our-products/ultra_light/">Ultra Light</a>
                      </li>
                      <li>
                        <a href="/en/our-products/protect_cool/">
                          Protect &amp; Cool
                        </a>
                      </li>
                      <li>
                        <a href="/en/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      <li>
                        <a href="/en/our-products/instantglow/">Instant Glow</a>
                      </li>
                      <li>
                        <a href="/en/our-products/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/en/our-products/wetskin/">Wet skin</a>
                      </li>
                      <li>
                        <a href="/en/our-products/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/en/our-products/aftersun/">After sun</a>
                      </li>
                      <li>
                        <a href="/en/our-products/all_products/">
                          All products
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/en/our-heritage/" target="_self">
                      Our Heritage
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/en/know_the_sun/" target="_self">
                      Know the sun
                    </a>
                  </li>
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      countries
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-products/all_products/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/all_products/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/galeria/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/todos_os_Produtos/">PT</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/gallery/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/gallery/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="content">
              <div className="gallery-page">
                <h1 className="gallery-page__title">
                  ALL PIZ BUIN<sup>®</sup> PRODUCTS
                </h1>
                <div className="clearfix">
                  <div className="gallery-page__filter hidden">
                    <div className="arrow">
                      <br />
                    </div>
                    <form method id="fmFilter">
                      <select
                        name="filter"
                        className="gallery-page__filter__select"
                      >
                        <optgroup>
                          <option value="0">Filter by: All</option>
                          <option value="1,2,6,9,12,13">Sensitive skin</option>
                          <option value="12,18,19">After sun</option>
                          <option value="1,2,4">Face protection</option>
                          <option value="1">Fast-absorbing</option>
                          <option value="13">Waterproof</option>
                        </optgroup>
                      </select>
                    </form>
                  </div>
                </div>
                <div className="innerContent clearfix gallery-page__list">
                  <a
                    href="/en/our-products/moisturising/#sun-lotion"
                    className="gallery-page__list__item"
                    data-pid="1"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/9bc8a3db486e983ed341e9f1b35e6fea_f17.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Moisturising
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SUN LOTION
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/en/our-products/moisturising/#radiant-face-cream"
                    className="gallery-page__list__item"
                    data-pid="1"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/0d86592c1644d8ee1a39ed5b38706b37_f15.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Moisturising
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          RADIANT FACE CREAM
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/en/our-products/moisturising/#sun-lipstick"
                    className="gallery-page__list__item"
                    data-pid="1"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/615e871bfde5baa0d744c784b702bdf8_f18.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Moisturising
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SUN LIPSTICK
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/en/our-products/ultra_light/#dry-touch-sun-fluid"
                    className="gallery-page__list__item"
                    data-pid="2"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/9ad51eb4172e2e735a033e53fcc5bd91_f32.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Ultra Light
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          DRY TOUCH SUN FLUID
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/en/our-products/ultra_light/#dry-touch-face-fluid"
                    className="gallery-page__list__item"
                    data-pid="2"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/b69aaa9fbeaff7e34564b27dca782cda_f31.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Ultra Light
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          DRY TOUCH FACE FLUID
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/en/our-products/ultra_light/#hydrating-sun-spray"
                    className="gallery-page__list__item"
                    data-pid="2"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/8bf310057998eb9035da232074f98217_f33.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Ultra Light
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          HYDRATING SUN SPRAY
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/en/our-products/protect_cool/#refreshing-sun-mousse"
                    className="gallery-page__list__item"
                    data-pid="11"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/20ff908f0261eacb288e5e2c0e4a534e_f368.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Protect &amp; Cool
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          Refreshing Sun Mousse
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/en/our-products/tan_protect/#tan-accelerating-oil-spray"
                    className="gallery-page__list__item"
                    data-pid="3"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/77e4033d5fafdcbe769a3bdddbb7511a_f29.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Tan &amp; Protect
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          TAN ACCELERATING OIL SPRAY
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/en/our-products/tan_protect/#tan-intensifying-sun-lotion"
                    className="gallery-page__list__item"
                    data-pid="3"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/cdb96eb7dd5017e10ae2166b22fc0b37_f28.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Tan &amp; Protect
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          TAN INTENSIFYING SUN LOTION
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/en/our-products/tan_protect/#tan-intensifying-sun-spray"
                    className="gallery-page__list__item"
                    data-pid="3"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/8aaa31174ebb0f87a0ac390a10ba9276_f30.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Tan &amp; Protect
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          TAN INTENSIFYING SUN SPRAY
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/en/our-products/instantglow/#skin-illuminating-sun-spray"
                    className="gallery-page__list__item"
                    data-pid="9"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/2369ab6310d72b7b1b51e9a9f72eb79c_f99.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Instant Glow
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          Skin illuminating sun spray
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/en/our-products/instantglow/#skin-illuminating-sun-lotion"
                    className="gallery-page__list__item"
                    data-pid="9"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/358d047f70bae5a3276b9144733fd4fb_f404.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Instant Glow
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          Skin Illuminating Sun Lotion
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/en/our-products/allergy/#sun-sensitive-skin-lotion"
                    className="gallery-page__list__item"
                    data-pid="4"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/9dbc686120d2a4274e5ea01839fc89e7_f22.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Allergy
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SUN SENSITIVE SKIN LOTION
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/en/our-products/allergy/#sun-sensitive-skin-spray"
                    className="gallery-page__list__item"
                    data-pid="4"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/346944a988e5e02eb8456a76d5f86be2_f23.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Allergy
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SUN SENSITIVE SKIN SPRAY
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/en/our-products/allergy/#sun-sensitive-skin-face-cream"
                    className="gallery-page__list__item"
                    data-pid="4"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/806b5ebeb3e93f418b490dafefe9f5b3_f21.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Allergy
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SUN SENSITIVE SKIN FACE CREAM
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/en/our-products/wetskin/#transparent-sun-spray"
                    className="gallery-page__list__item"
                    data-pid="5"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/66e701e7b055c34a5ea70cc9414e3611_f34.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Wet Skin
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          TRANSPARENT SUN SPRAY
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/en/our-products/mountain/#mountain-suncream"
                    className="gallery-page__list__item"
                    data-pid="6"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/6dbc9c111c0b9ae9db8aaa8f89873294_f27.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Mountain
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          MOUNTAIN SUNCREAM
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/en/our-products/mountain/#mountain-suncream-lipstick"
                    className="gallery-page__list__item"
                    data-pid="6"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/d7c5f1cffc265a491e721285affdcad3_f24.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Mountain
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          MOUNTAIN SUNCREAM + LIPSTICK
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/en/our-products/mountain/#mountain-lipstick"
                    className="gallery-page__list__item"
                    data-pid="6"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/11cfa6eaadc71b321ef378438ce0de09_f26.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Mountain
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          MOUNTAIN LIPSTICK
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/en/our-products/mountain/#glacier-cream"
                    className="gallery-page__list__item"
                    data-pid="6"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/c035e1337afdcf2d9bfa493b98906844_f25.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          Mountain
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          GLACIER CREAM
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/en/our-products/aftersun/#soothing-cooling-moisturising-lotion"
                    className="gallery-page__list__item"
                    data-pid="7"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/50bd584a56ad4a4192a4d62a51707549_f20.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          After Sun
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          SOOTHING &amp; COOLING MOISTURISING LOTION
                        </h3>
                      </div>
                    </div>
                  </a>
                  <a
                    href="/en/our-products/aftersun/#tan-intensifying-moisturising-lotion"
                    className="gallery-page__list__item"
                    data-pid="7"
                    data-mpid
                  >
                    <div className="gallery-page__list__item__container">
                      <div className="gallery-page__list__item__image">
                        <img src="/assets/images/af086f7db8f820e11c560f2b03013191_f19.jpg" />
                      </div>
                      <div className="gallery-page__list__item__details">
                        <h4 className="gallery-page__list__item__details__category">
                          After Sun
                        </h4>
                        <h3 className="gallery-page__list__item__details__title">
                          TAN INTENSIFYING MOISTURISING LOTION
                        </h3>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        
        <script
          dangerouslySetInnerHTML={{
            __html:
              '\n$(document).ready(function(){\nvar i = $(".heroImage > img").attr(\'src\');\n$("#bgImage").css("background-image","url("+i+")");\n$(".heroImage").empty();\n})\n'
          }}
        />
      </div>
    );
  }
}

export default Page;
